import React from 'react';
import { Helmet } from "react-helmet";
import { ENV_NAME } from '../config.js';


export const TagManager = () => {

    return(
        <Helmet>
            { ENV_NAME === "production" && (
                <script>{`
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-NH8TQFZ');
                `}</script>
            )}
        </Helmet>
    );
}


export const TagManagerFooter = () => {

    return(
        <noscript>{`
            <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NH8TQFZ"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>
        `}</noscript>
    );
}
