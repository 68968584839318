import React from "react";
import Facebook from '../images/icons/Facebook.svg';
import Instagram from '../images/icons/Instagram.svg';
import Youtube from '../images/icons/Youtube.svg';
import Linkedin from '../images/icons/Linkedin.svg';
import s from './SocialMedia.module.scss';

const SocialMedia = () => {
  return (
      <div className={s.container}>
          <div className={s.socialMedia}>
              <a href={'https://www.facebook.com/Agiliscreditos/'} target='__blank'>
                  <img className={s.icons} src={Facebook} alt={"Facebook icon"}/>
              </a>
              <a href={'https://www.instagram.com/agiliscreditos/'} target='__blank'>
                  <img className={s.icons} src={Instagram} alt={"Instagram icon"}/>
              </a>
              <a href={'https://www.youtube.com/channel/UCtwSWgP-96YtQvBawFGsz3w?view_as=subscriber'} target='__blank'>
                  <img className={s.icons} src={Youtube} alt={"Youtube icon"}/>
              </a>
              <a href={'https://www.linkedin.com/company/agilis-prestamos/'} target='__blank'>
                  <img className={s.icons} src={Linkedin} alt={"Linkedin icon"}/>
              </a>
          </div>
      </div>

    )
}

export default SocialMedia
