import React from "react";
import s from './ChecksHeaderMobile.module.scss';
import blueCheck from '../images/icons/Icono-check-circular-azul.svg';

const ChecksHeaderMobile = ({ children }) => {
  return(
      <div className={s.check}>
            <img src={blueCheck} alt='check azul'/>
           {children}
      </div>
  )
}

export default ChecksHeaderMobile
