import React from 'react';
import s from './UserInfo.module.scss';
import { Link } from "gatsby";
import { FformatMoney } from '../../utils';
import Input from './Input';
import Button from './Button';


const UserInfo = ({ sliderValue, selectedTerm, backButton, handleChangeUser, submitUserInfo, first_name, last_name, phone, email, fee, errors }) => {

    return (
        <div >
            <h6 className={s.simTitle}>Recibí más información de tu crédito</h6>
            <div className={s.sumaryCard}>
                <div className={s.sumaryContainer}>
                    <div className={s.valueWraper}>
                    <p className={s.text}>Monto</p>
                    <p className={s.value}><strong className={s.currency}>$</strong> {FformatMoney(sliderValue)}</p>
                    </div>
                    <div className={s.valueWraper}>
                        <p className={s.text}>Cuota inicial de</p>
                        <p className={s.value}><strong className={s.currency}>$</strong> {FformatMoney(fee)}</p>
                    </div>
                    <div className={s.valueWraper}>
                        <p className={s.text}>Cuotas</p>
                        <p className={s.value}>{selectedTerm}</p>
                    </div>
                </div>
                <p className={s.legalText}>Tasa Nominal Anual (TNA): 63%</p>
            </div>
            <div className={s.nameInputWraper}>
                <Input name='first_name' value={first_name} label='Nombre' onChange={handleChangeUser}/>
                <Input name='last_name' value={last_name} label='Apellido'  onChange={handleChangeUser}/>
            </div>
            <Input name='email' label='Email' value={email} className={s.inputLarge} onChange={handleChangeUser} error={errors.email && errors.email[0]}/>
            <Input name='phone' label='Celular' type='tel' value={phone} className={s.inputLarge}  onChange={handleChangeUser} error={errors.phone && errors.phone[0]}/>
            {errors.phone && errors.phone[0] ? null : <p className={s.legalText}>Ej.: 1160001234 o 2216000123 (Cod. de área sin el 0 + Número sin el 15)</p>}
            <div className={s.buttonsContainer}>
                <Button text='Volver a simular' className={s.back} onClick={backButton}/>
                <Button text='Continuar' className={s.continue} onClick={submitUserInfo} disabled={(first_name && last_name && phone && email) ? false : true}/>
            </div>
            <p className={s.legalText}>Al continuar estás aceptando los <Link to="/tyc" className={s.tyc}>Términos y condiciones</Link></p>
        </div>
    )
};

export default UserInfo;
