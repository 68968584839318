import React from 'react';
import s from './Simulator.module.scss';
import Button from './Button';
import './rcSlider.css';
import Slider from 'rc-slider';
import Select from './Select';
import { FformatMoney } from '../../utils';

const Simulator = ({destinations, destinationChange, sliderValue, selected, buttonTermSelect, sliderChange, selectedTerm, simContinueButton, fee, defaultDestination, selectedDestination, errors}) => {
    
    return (
        <div>
            <h6 className={s.simTitle}>Simulá la cuota de tu Crédito con garantía</h6>
            <Select options={destinations} name='destination' onChange={destinationChange} value={selectedDestination} defaultDestination={defaultDestination} error={errors.destination}/>
            <p className={s.subTitle}>¿Cuánto dinero necesitás?</p>
            <p className={s.sliderValue}><strong className={s.currency}>$</strong> {FformatMoney(sliderValue)}</p>
            <Slider defaultValue={sliderValue} min={selected.min_amount} max={selected.max_amount} onChange={(value) => sliderChange(value)}/>
            <div className={s.rangeContainer}>
                <p className={s.sliderRange}><strong className={s.currencyRange}>$</strong> {FformatMoney(selected.min_amount)}</p>
                <p className={s.sliderRange}><strong className={s.currencyRange}>$</strong> {FformatMoney(selected.max_amount)}</p>
            </div>
            <p className={s.subTitle}>¿En cuántos meses querés pagarlo?</p>
            <div className={s.termsContainer}>
                {selected.terms && selected.terms.map((term, index) => (
                    <Button key={index} children={term} name={term} onClick={buttonTermSelect} className={(selectedTerm === term)? s.activeTermButton : s.termButton}/>
                ))}
            </div>
            <p className={s.subTitle}>Cuota inicial desde</p>
            <p className={s.sliderValue}><strong className={s.currency}>$</strong> {FformatMoney(fee)}</p>
            <div className={s.buttonWraper}>
                <Button text='Continuar' className={s.continue} onClick={simContinueButton} disabled={(selectedTerm) ? false : true}/>
            </div>
            <p className={s.tyc}>*Cuota calculada con una TNA del 63%, la oferta final puede diferir de los montos exhibidos en este simulador.</p>
        </div>
    )
};

export default Simulator;
