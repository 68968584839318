import React from "react";
import s from './Experts.module.scss';
import SocialMedia from './SocialMedia';
import Button from './Button.js';

const ExpertosAgilis = ({ image }) => {

  return (
      <div className={s.container} id='contacto'>
        <p className={s.title}>Hablá con un experto AGILIS</p>
        <p className={s.subtitle}>Lunes a Viernes de 9 a 19 Hs.</p>
        {image && <img src={image} alt='Imagen de asesor'/>}
        <div className={s.buttonsContainer}>
            <Button className={s.btnExperts} miniText={"LLAMANOS"} text={"6842-0740"} href="tel:+5491168420740" style={{padding: "9px 30px"}}/>
            <Button className={s.btnExperts} miniText={"CHATEANOS"} text={"WhatsApp"} href={`https://api.whatsapp.com/send?phone=5491164412609&text=Hola`} style={{padding: "9px 30px"}}/>
            <Button className={s.btnExperts} miniText={"ESCRIBINOS"} text={"info@agilis.com.ar"} href="mailto: info@agilis.com.ar" style={{padding: "9px 30px"}}/>
        </div>
        <div className={s.address}>
            <p className={s.street}>Mariscal Antonio José de Sucre 1530</p>
            <p className={s.CP}>C1428 CABA, Oficina 903</p>
        </div>
        <SocialMedia/>
      </div>
    )
}

export default ExpertosAgilis
