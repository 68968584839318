import React from 'react';
import s from './Select.module.scss'

const Select = ({ name, options, onChange, value=null, defaultDestination, error }) => {
    return(
        <div className={`${s.container}`}>
            <select name={`${name}`} onBlur={onChange} defaultValue={(value) ? value : defaultDestination} className={s.select}>
                <option disabled>{defaultDestination}</option>
                {options && options.map((option, index) => (
                    <option key={index} value={option}>{option}</option>
                ))}
            </select>
            <div className={s.errorContainer}>{error && <p className={s.error}>{error}</p>}</div>
        </div>
    );
}
export default Select
