import React from "react";
import s from './BurgerMenu.module.scss';
import Close from '../images/icons/Close-button.svg';
import Open from '../images/icons/Icono-hamburger.svg';
import Blue from '../images/icons/burger-blue.svg';

const BurgerMenu = ({ children, open, toggleOpen, blue }) => {
    return (
      <div className={s.menuContainer}>
          <div className={[s.burgerContainer, open ? s.change : ''].join(' ')} onClick={toggleOpen} onKeyDown={toggleOpen} role="button" tabIndex="0">
              {<img src={(!open && blue) ? Blue : Open} alt={"menu colapsable"}/>}
          </div>
          <div className={[s.menu, !open ? s.hidden : ''].join(' ')}>
            <div className={s.closeIcon}>
                <button className={s.closeButton} onClick={toggleOpen}><img src={Close} alt={"cerrar"}/></button>
            </div>
            {children({ toggleOpen: toggleOpen })}
          </div>
      </div>);
  }

export default BurgerMenu
