import React from 'react';
import { Helmet } from "react-helmet";
import { URL_BASE } from '../config.js';


export const PageView = () => {

    return(
        <Helmet>
            <script>{`
                var xmlhttp = new XMLHttpRequest();
                var theUrl = "${URL_BASE + "/api/v2/page-view/"}";
                xmlhttp.withCredentials = true;
                xmlhttp.open("POST", theUrl);
                xmlhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
                xmlhttp.send(JSON.stringify({'view': window.location.href, 'referrer': document.referrer}));
            `}</script>
        </Helmet>
    );
}

export default PageView
