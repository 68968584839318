import React from 'react';


export const Links = ({ title, href, src, alt, className, onClick, target }) => {
    return(
        <li>
            <a href={href} target={target} onClick={onClick}>{title}
            {src && <img className={className} src={src} alt={alt}/>}
            </a>
        </li>
    );
}

export default Links;
