import React from "react";
import s from './ChecksHeader.module.scss';
import whiteCheck from '../images/icons/Icono-check-circular-blanco.svg';

const ChecksHeader = ({ children, simulator }) => {
  return(
      <div className={(simulator) ?  s.checkSimulator : s.check}>
            <img src={whiteCheck} alt='check blanco'/>
            {children}
      </div>
  )
}

export default ChecksHeader
