import React from 'react';
import s from './OutlineButton.module.scss';


const OutlineButton = ({ text , onClick, style }) => {

  return (
    <button onClick={onClick} className={s.outlineButton} style={style}>
        {text}
    </button>
  )
};

export default OutlineButton;
