import React from "react";
import axios from 'axios';
import s from './SimulatorContainer.module.scss';
import { URL_BASE } from '../config.js';
import Simulator from './Simulator';
import UserInfo from './UserInfo';
import { calculate_payment, isValidCharacter } from '../../utils';

// We can just import Slider or Range to reduce bundle size
// import Slider from 'rc-slider/lib/Slider';
// import Range from 'rc-slider/lib/Range';

class SimulatorContainer extends React.Component {
    state = {
        selected: {},
        destinationData: [],
        sliderValue: 250000,
        simPage: false,
        userpage: false,
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        defaultDestination: 'Seleccione el destino del préstamo',
        selectedDestination: '',
        errors: {
            destination:'',
            email: '',
            phone: '', 
        },
    }

    componentDidMount(){
        axios.defaults.withCredentials = true
        axios.defaults.baseURL = URL_BASE;
        axios.get(`/api/v3/products/?type=CE`)
        .then((response)=> {
            this.setState({
                destinationData: response.data,
                selectedTerm: response.data[0].preferred_term,
                selected: response.data[0],
                simPage: true,
                fee: calculate_payment(this.state.sliderValue, response.data[0].preferred_term, response.data[0].product),
            });
        })
        .catch(({ response }) => {
        });
    }

    destinationChange = (e) => {
        let value = e.target.value;
        if(value !== this.state.defaultDestination){
            let selected = this.state.destinationData.find((x) => x.destination===value);
            this.setState({
                selected: selected,
                selectedDestination: selected.destination,
                errors: {
                    ...this.state.errors,
                    destination:'',
                },
            });
        }
    }

    sliderChange = (value) => {
        let fee = calculate_payment(value, this.state.selectedTerm, this.state.selected.product);
        this.setState({
            sliderValue: value,
            fee: fee,
        });
    }

    buttonTermSelect = (e) => {
        let name = e.target.name;
        let selected = this.state.selected.terms.find(term => term === Number(name));
        let fee = calculate_payment(this.state.sliderValue, selected, this.state.selected.product);
        this.setState({
            selectedTerm: selected,
            fee: fee,
        });
    }

    simContinueButton = () =>{
        if(this.state.selectedDestination){
            this.setState({
                simPage: false,
                userpage: true,
                errors: {},
            });
        }else{
            this.setState({
                errors: {
                    ...this.state.errors,
                    destination: 'Ingresar el destino de tu crédito',
                }
            });
        }
        
    }

    handleChangeUser = (e) =>{
        let name = e.target.name;
        let value = e.target.value;
        if(isValidCharacter(name, value)){
            this.setState({
                [name]: value,
            });
        }
    }

    backButton = () =>{
        this.setState({
            simPage: true,
            userpage: false,
        });
    }

    submitUserInfo = () =>{
        const {selected, sliderValue, selectedTerm, first_name, last_name, phone, email } = this.state;
         
        axios.post(`/api/v3/landing-form/`,
        {   app_type: "CE",
            first_name: first_name, 
            last_name: last_name,
            phone: phone,
            email: email,
            amount: sliderValue,
            term: selectedTerm,
            destination: selected.destination
        },)
        .then((response)=> {
            this.setState({
                first_name: '',
                last_name: '',
                phone: '',
                email: '',
            });
            window.location.href = response.data.redirect_to;
        })
        .catch(({ response }) => {
            const errors = {};
            Object.keys(response.data).forEach(field => {
                errors[field] = response.data[field];
            });
            this.setState({
                errors: errors,
            });
        });   
    }
  
    render(){
        const { destinationData, sliderValue, selected, selectedTerm, simPage, userpage, first_name, last_name, phone, email, fee, defaultDestination, selectedDestination, errors } = this.state;
        let destinations = destinationData.map((x) => x.destination)
        return(
            <div className={s.simulatorCard}>
                {simPage && <Simulator destinations={destinations} defaultDestination={defaultDestination} destinationChange={this.destinationChange} sliderChange={this.sliderChange} sliderValue={sliderValue} selected={selected} buttonTermSelect={this.buttonTermSelect} selectedTerm={selectedTerm} simContinueButton={this.simContinueButton} fee={fee} selectedDestination={selectedDestination} errors={errors}/>}
                {userpage && <UserInfo sliderValue={sliderValue} selectedTerm={selectedTerm} backButton={this.backButton} handleChangeUser={this.handleChangeUser} submitUserInfo={this.submitUserInfo} first_name={first_name} last_name={last_name} phone={phone} email={email} fee={fee} errors={errors}/>}
            </div>
        )
    }
}

export default SimulatorContainer