var env_name = require('./env_data.json');

var env = {
    "local": {
        "API_URL": "http://localhost:8000",
        "MAIN_URL": "http://localhost:8000/",
    },
    "staging": {
        "API_URL": "https://alphapi.agilis.com.ar",
        "MAIN_URL": "https://alpha.agilis.com.ar/",
    },
    "production": {
        "API_URL": "https://api.agilis.com.ar",
        "MAIN_URL": "https://agilis.com.ar/",
    }
}

var active_env = env[env_name.ENV_NAME || 'local'];

export const URL_BASE = active_env.API_URL;
export const MAIN_URL = active_env.MAIN_URL;
export const ENV_NAME = env_name.ENV_NAME;
export const TOP_LEVEL_PREFIX = '/ar';
export const DESKTOP = '(min-width: 1400px)'
export const LAPTOP = '(min-width: 500px) and (max-width: 1400px)'
export const MOBILE = '(min-width: 200px) and (max-width: 500px)'

