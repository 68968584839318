import React from 'react';
import { Helmet } from "react-helmet";
import { SEO } from './seo'; //this have all the SOCIAL seo


import favicon_a   from "../images/favicon/apple-touch-icon.png"
import favicon_32  from "../images/favicon/favicon-32x32.png"
import favicon_16  from "../images/favicon/favicon-16x16.png"
import favicon_saf from "../images/favicon/safari-pinned-tab.svg"



export const MetaData = () => {

    return(
        <>
            <SEO /> 
            <Helmet>
                <meta charset="UTF-8" />
                <meta name="description" content="Te pre-aprobamos online en 2 minutos y gestionamos todo para que tengas tu préstamo acreditado en menos de 2 semanas! ¡Calculá tus cuotas ahora!" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="keywords" content="Creditos garantizados hipotecario vehicular" />
                <meta name="author" content="Creditos Garantizados S.A.S." />
                
                <link rel="preconnect" href="https://connect.facebook.net"></link>
                <link rel="preconnect" href="https://www.facebook.com"></link>
                <link rel="preconnect" href="https://stats.g.doubleclick.net"></link>
                <link rel="preconnect" href="https://www.google.com"></link>
                <link rel="preconnect" href="https://api.agilis.com.ar"></link>
                <link rel="preconnect" href="https://www.google-analytics.com"></link>
            
                <link rel="apple-touch-icon" sizes="180x180" href={`${favicon_a}`}></link>
                <link rel="icon" type="image/png" sizes="32x32" href={`${favicon_32}`}></link>
                <link rel="icon" type="image/png" sizes="16x16" href={`${favicon_16}`}></link>
                <link rel="mask-icon" href={`${favicon_saf}`} color="#5bbad5"></link>

                <meta name="msapplication-TileColor" content="#da532c" />
                <meta name="theme-color" content="#ffffff" />

                <title>AGILIS</title>
                
                <html lang='es' />
            </Helmet>
        </>
    );
}

export default MetaData
