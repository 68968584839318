import React from "react";
import Button from "./Button.js";
import s from "./CTAFooter.module.scss";
import { navigate } from '@reach/router';


const CTAFooter = ({ children, onClick, blue, href }) => {
  return (
    <div className={s.container}>
        <div className={s.mainDiv}>
            <p className={s.text}>
                {children}
            </p>
            <Button onClick = {blue ? 'null' : () => navigate('#header')} href={href} >
                SIMULÁ TU CUOTA
            </Button>
        </div>
    </div>
  )
}

export default CTAFooter
