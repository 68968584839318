
export function formatMoney(number) {
    return parseFloat(number).toLocaleString('es-AR');
}

export function FformatMoney(n){
    return formatMoney(Math.floor(n));
}

export const capitalize = (fullName) => {
    return fullName.toLowerCase().split(' ').map((name) => name.charAt(0).toUpperCase() + name.substring(1)).join(' ')
}

export const calculate_payment = (kd, n, product) => {
    let K = kd + product.expenses1
    let t = product.tna / 12;
    let z = (1 - (1 / (1 + t)) ** n) / t;
    let base = K / z;
    let ii = K * t;
    let ki = base - ii;
    let exp = K * product.expenses2 + product.expenses3;
    let iva_i = ii * 0.21;
    let payment = ki + ii + iva_i + exp
    return payment
}


export const isValidCharacter = (name, value) => {
    let latin_neg = /[\d!@#$%^,.°&*()_~þÞ+=|?¿¡/"{}[\]\\]/;
    let latin_mail_neg = /[,°()~þÞ¿¡/"[\]\\]/;
    if(name === "first_name" && latin_neg.test(value)){
        return false;
    }
    if(name === "last_name" && latin_neg.test(value)){
        return false;
    }
    if(name === "email" && latin_mail_neg.test(value)){
        return false;
    }
    if(name === "phone" && /[^\d+]/.test(value)){
        return false;
    }

    return true;     
}
