import React from 'react';
import s from './MobileMenu.module.scss';
import { navigate } from '@reach/router';
import OutlineButton from './OutlineButton';
import rightArrowBlack from '../images/icons/right-arrow-black.svg';
import Links from './Links';
import ProfileMenu from './ProfileMenu';
import MobileSecondaryMenu from './MobileSecondaryMenu';
import { TOP_LEVEL_PREFIX, URL_BASE } from '../config.js';

export default class MobileMenu extends React.Component {

    state = {
      showMenu: false,
    }

    toggleMenuCredits = (e) => {
        e.preventDefault();
        this.setState({ showMenu: !this.state.showMenu });
    }

    render(){
        const { user, userData, productType } = this.props;
        const { showMenu } = this.state;
        return(
            <div className={s.burgerLinks}>
                {user
                    ? <ProfileMenu mobileMenu={true} userData={userData}/>
                    : (productType !== 'CarPur')
                    ? <div className={s.loginContainer}>
                        <p className={s.loginText}><i>¿Ya sos usuario?</i></p>
                        <a className={s.links} href={`${TOP_LEVEL_PREFIX}/app/auth/login?next=/`}><OutlineButton text='Ingresá a tu cuenta' style={{width: '48%'}}/></a>
                    </div>
                    : <a className={s.links} href={`${URL_BASE}/app/clear-app/?next_url=/prendas`}><OutlineButton text='Iniciar nueva solicitud' style={{width: '48%'}}/></a>
                }
                <ul className={s.listMobile}>
                    <Links title='Nosotros' href='/nosotros' src={rightArrowBlack} className={s.rightArrowBlack} alt={'rightArrowBlack'}/>
                    <Links title='Nuestros Créditos' onClick={this.toggleMenuCredits} src={rightArrowBlack} className={s.rightArrowBlack} alt={'rightArrowBlack'}/>
                    <MobileSecondaryMenu showMenu={showMenu} title='Nuestros Créditos' toggleMenuCredits={this.toggleMenuCredits}>
                        <div className={s.creditMenu}>
                            <Links title='Libre destino con Garantía Vehícular' href='/car' src={rightArrowBlack} className={s.rightArrowCredit} alt={'rightArrowCredit'}/>
                            <Links title='Libre destino con Garantía Hipotecaria' href='/home' src={rightArrowBlack} className={s.rightArrowCredit} alt={'rightArrowCredit'}/>
                        </div>
                    </MobileSecondaryMenu>
                    <Links title='Preguntas Frecuentes' href='/faqs' src={rightArrowBlack} className={s.rightArrowBlack} alt={'rightArrowBlack'}/>
                    <Links title='Revista' href='https://blog.agilis.com.ar/' src={rightArrowBlack} className={s.rightArrowBlack} alt={'rightArrowBlack'}/>
                    <Links title='Contacto' onClick={(productType !== 'Nosotros') ? () => navigate('#contacto') : () => navigate('/#contacto')} src={rightArrowBlack} className={s.rightArrowBlack} alt={'rightArrowBlack'}/>
                </ul>
        </div>
        );
    }
}
