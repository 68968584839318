import React from "react";
import axios from 'axios';
import s from './Header.module.scss';
import BackgroundImage from 'gatsby-background-image';
import NavBar from './NavBar';
import ChecksHeader from './ChecksHeader';
import ChecksHeaderMobile from './ChecksHeaderMobile';
import { URL_BASE } from '../config.js';
import SimulatorContainer from './SimulatorContainer';

class Header extends React.Component {

  state = {
      user: false,
  }

  componentDidMount(){
      axios.defaults.withCredentials = true
      axios.defaults.baseURL = URL_BASE;
      axios.get(`/api/v3/auth/profile/`)
      .then((response)=> {
          if(response.data.id){
              this.setState({
                  user: true,
                  userData: response.data,
              });
          }else{
            this.setState({
                user: false,
            });
          }
      })
      .catch(({ response }) => {
      });
  }

  render(){
      const { children, preTitle, title, subTitle, className, checks, subLanding, backgrounds, productType, simulator, jobs } = this.props;
      const { user, userData } = this.state;
      return(
        <div>
            <BackgroundImage 
              Tag='header'
              className={className}
              title='Agilis'
              fluid={backgrounds}
              backgroundColor={`#1950b7`}
            >
                <NavBar user={user} userData={userData} productType={productType}/>
                <div className={`${(simulator) && s.simulator}`}>
                    <div>
                        <h6 className={`${s.firstTitle} ${(subLanding) && s.firstTitleSub} ${(simulator) && s.simulatorTitle}`}>{preTitle}</h6>
                        <h1 className={`${s.mainTitle} ${(simulator) && s.simulatorMainTitle}`}>{title}</h1>
                        <span className={s.subTitle} dangerouslySetInnerHTML={{__html: subTitle}}></span>
                        <div className={s.creditButtonContainer}>
                            {children}
                        </div>
                        <div className={(simulator) ? s.checksContainerSimulator : s.checksContainer}>
                            {checks && Object.values(checks[0]).map((check, index) =>
                                <ChecksHeader key={index} simulator={simulator}>
                                    <span className={s.checkText} dangerouslySetInnerHTML={{__html: check.html}}></span>
                                </ChecksHeader>
                            )}
                        </div>
                    </div>
                    {(simulator) &&
                        <SimulatorContainer/>                            
                    }
                </div>
                
            </BackgroundImage> 
            <div className={s.checksContainerMobile}>
              {checks && Object.values(checks[0]).map((check, index) =>
                  <ChecksHeaderMobile key={index}>
                    <span className={s.checkTextMobile} dangerouslySetInnerHTML={{__html: check.html}}></span>
                  </ChecksHeaderMobile>
              )}
                {(!simulator && !jobs) && <hr className={s.line}/>}
            </div>
        </div>
      )
  }
}

export default Header
