import React, { Component } from "react";
import Slider from "react-slick";
import './CenterMode1.css';
import './CenterMode.css';

export default class CenterMode extends Component {
  render() {
    const { medios, slidesNumber } = this.props;
    const settings = {
      className: "center",
      centerMode: true,
      arrows: (medios) ? false : true,
      infinite: true,
      centerPadding: "10px",
      speed: 500,
      autoplay: (medios) ? true : false,
      dots: (medios) ? true : false,
      lazyLoad: 'progressive',
      responsive: [
        { breakpoint: 800, settings: { slidesToShow: 1 } },
        { breakpoint: 2560, settings: { slidesToShow: (slidesNumber) ? slidesNumber : 3 } },
      ]
    };
    return (
        <Slider {...settings}>
          {this.props.children}
        </Slider>
    );
  }
}
