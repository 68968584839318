import React from 'react';
import s from './MobileSecondaryMenu.module.scss';
import leftArrowBlack from '../images/icons/back_1.svg';


export const MobileSecondaryMenu = ({ children, title, showMenu, toggleMenuCredits }) => {
    return(
        <div style={{ display: showMenu ? 'block' : 'none'}} className={s.menuContainer}>
            <div className={s.titleContainer}>
                <button className={s.closeButton} onClick={toggleMenuCredits}><img className={s.backIcon} src={leftArrowBlack} alt={'flecha izquierda negra'}/></button>
                <h3 className={s.mobileTitle}>{title}</h3>
            </div>
            {children}
        </div>
    );
}

export default MobileSecondaryMenu;
