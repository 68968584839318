import React from "react";
import Logo from "../images/Logo-Agilis-blanco.svg";
import s from "./Footer.module.scss";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";


const Footer = ({ productType, jobs }) => {
    const data = useStaticQuery(graphql`{
        gcms {
            footers (where: {status: PUBLISHED}) {
               textLine {
                    html
                }
               productType
            }
        },
        fiscal: file(relativePath: { eq: "data-fiscal.jpg"}) {
            childImageSharp {
                fixed(quality: 90, width: 52) {
                    ...GatsbyImageSharpFixed_withWebp_noBase64
                }
            }
        },
        
    }`);
    
    const footers = data.gcms.footers;

    let footers2 = footers.filter(function(x) {
        return x.productType === productType;
    })
    if (footers2.length === 0) {
        footers2 = footers.filter(function(x) {
            return x.productType === "General";
        })
    };

    return (
        <div className={s.container}>
            <div className={s.paragraph} dangerouslySetInnerHTML={{__html: footers2.map((text, index) => text.textLine.html)}}></div>
            <hr className={s.line}/>
            <div className={s.infoBox}>
                <img src={Logo} alt={"Logo Agilis"}/>
                <div className={s.infoContainer}>
                    <div className={s.linksContainer}>
                        {/* <a href="/jobs">
                            <p className={s.text}>Trabajá con nosotros</p>
                        </a> */}
                        <div className={s.tycContainer}>
                            <a href="/tyc">
                                <p className={s.linkText}>Términos y condiciones</p>
                            </a>
                            <p className={s.linkText}>|</p>
                            <a href="/politicas-privacidad">
                                <p className={s.linkText}>Políticas de privacidad</p>
                            </a>
                        </div>
                        <p className={s.text}>Mariscal Antonio José de Sucre 1530 Oficina 903 (C1428). CABA. Argentina.</p>
                        <p className={s.text}>© Préstamos Garantizados S.A.S 2019. Todos los derechos reservados.</p>
                    </div>
                    <a href='http://qr.afip.gob.ar/?qr=NroUqAjb1e_LZOC1qzMUjg,,' target='__blank' className={s.QR}>
                        <div style={{height: '70px', margin: 'auto'}} >
                            <Img fixed={data.fiscal.childImageSharp.fixed} alt={"Codigo QR AFIP"} />
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Footer
