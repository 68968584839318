import React from 'react';
import s from './Button.module.scss';

const Button = ({ children, text, miniText, style, className, href, onClick, name, disabled, icon, alt }) => {

    return (
        <button className={`${(className) ? className : s.CTA}`} style={style} onClick={onClick} name={name} disabled={disabled}>
        <a className={`${s.link} ${(icon) && s.linkIcon}`} href={href} target='__blank'>
            {(icon) && <img src={icon} className={s.icon} alt={alt}/>}
            {miniText ?
                <p className={s.miniText}>{miniText}</p>
                :
                null
            }
            <p className={miniText ? s.textContact : s.text}>{text}</p>
        </a>
        {children}
        </button>
    )
};

export default Button;
