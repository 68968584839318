import React from 'react';
import s from './Input.module.scss';

class Input extends React.Component {
    state = {
        focus: false,
    }

    toggleFocus = (bool) => {
        this.setState({
            focus: bool,
        });

    }
    handleBlur = () => {
        this.toggleFocus(false)
    }

    render() {
        const { className = '', label, value, placeholder, name, onChange, error, type, style } = this.props;
        const { focus } = this.state;
        return (
            <div className={s.inputContainer} style={style}>
                {label && <label htmlFor={label} className={`${s.label} ${focus || value || value === 0 ? s.focus : ''}`}>{label}</label>}
                    <input
                        onChange={onChange}
                        onFocus={() => this.toggleFocus(true)}
                        onBlur={this.handleBlur}
                        value={value}
                        className={`${s.input} ${className}`}
                        placeholder={placeholder}
                        name={name}
                        type={type}
                        style={style}
                    />
                    <div className={s.errorContainer}>{error && <p className={s.error}>{error}</p>}</div>
            </div>
        );
    }
}

export default Input;
