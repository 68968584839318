import React from 'react';
import s from './ProfileMenu.module.scss';
import axios from 'axios';
import { capitalize } from '../../utils';
import arrow_up from '../images/icons/up-arrrow-white-01.svg';
import arrow_down from '../images/icons/Dropdown arrow white.svg';
import rightArrowGrey from '../images/icons/right-arrow-black.svg';
import { TOP_LEVEL_PREFIX, URL_BASE } from '../config.js';


class ProfileMenu extends React.Component {

    state = {
        collapse: false,
    }
    componentDidMount = () => {
       document.addEventListener('click', this.handleClick, false)
    }

    componentWillUnmount = () => {
        document.removeEventListener('click', this.handleClick, false)
    }

    handleCollapse = (e) => {
        e.preventDefault();
        this.setState({
            collapse: !this.state.collapse
        })
    }

    handleClick = (e) =>{
        if(!this.node.contains(e.target)){
            this.setState({
                collapse:false
            })
        }
    }

    getAvatar = (text) => {
        return text.split().map((str) => str ? str[0].toUpperCase() : "").join('')
    }

    getEmail = (mail) => {
        return mail.split('@')[0]
    }

    logoutUser = (e) => {
        e.preventDefault();
        axios.defaults.withCredentials = true
        axios.defaults.baseURL = URL_BASE;
         axios.get('/api/v3/auth/logout/')
        .then(() => {
           window.location.href = "/";
        });
    }

    render() {
    const { collapse } = this.state;
    const { mobileMenu, userData } = this.props;

    let userName = userData && userData.first_name;
    let mail = userData && userData.email;
    let apptype = userData && userData.app_type;
    return (
        <div className={s.profileContainer} ref={node => this.node = node}>
            <div onClick={this.handleCollapse} className={s.profileBut} onKeyDown={this.handleCollapse} role="button" tabIndex="0">
                <div className={s.profileIcon}>
                    <p className={s.profileAvatar}>{(userName) ? this.getAvatar(userName) : (mail && this.getAvatar(mail))}</p>
                </div>
                <p className={s.profileName} >{(userName) ? capitalize(userName) : ((mail) ? this.getEmail(mail) : '')}</p>
                 {!mobileMenu ? (collapse) ? <img className={s.arrowIcon} src={arrow_up} alt='flecha hacia arriba'/> : <img className={s.arrowIcon} src={arrow_down} alt='flecha hacia abajo'/> : ''}
            </div>
            <div  className={(collapse || mobileMenu) ? `${s.openElement} collapse` : s.collapseElement} id="collapseExample" >
                <div className={s.profileMenu}>
                    <div className={s.profileBody} >
                        <div className={s.creditContainer}>
                            <a href={`${TOP_LEVEL_PREFIX}/app/${apptype && apptype.toLowerCase()}/completa-tus-datos/mi-prestamo`} className={s.textCredit}>Editar mi solicitud</a><br/>
                            {mobileMenu &&
                                <a className={s.faq} href={`${TOP_LEVEL_PREFIX}/app/${apptype && apptype.toLowerCase()}/completa-tus-datos$/mi-prestamo}`}>
                                    <img className={s.rightArrow} src={rightArrowGrey} alt='flecha derecha gris'/>
                                </a>
                            }
                        </div>
                        <div className={s.creditContainer}>
                            <a href={`${TOP_LEVEL_PREFIX}/app/auth/cambia-contrasena/?next=${window.location.pathname}`} className={s.textCredit}>Cambiar contraseña</a> <br />
                            {mobileMenu &&
                                <a className={s.faq} href={`${TOP_LEVEL_PREFIX}/app/auth/cambia-contrasena/?next=${window.location.pathname}`}>
                                    <img className={s.rightArrow2} src={rightArrowGrey} alt='flecha derecha gris'/>
                                </a>
                            }
                        </div>
                        <div className={s.creditContainer}>
                            {mobileMenu &&
                                <div>
                                    <button onClick={this.logoutUser} className={s.textCredit}>Salir</button> <br />
                                    <button className={s.closeButton} onClick={this.logoutUser}>
                                        <img className={s.rightArrow3} src={rightArrowGrey} alt='flecha derecha gris'/>
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                    <div className={s.logoutContainer}>
                        <button className={s.closeButton} onClick={this.logoutUser}>Salir</button>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

export default ProfileMenu;
